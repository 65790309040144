.portal-img {
  flex: 8;
  height: 100%;
  color: #fff;
  padding: 42px;
  position: relative;

  .logo-login {
    position: absolute;
    top: 60px;
    left: calc(18% - 45px);
    width: 169px;
    height: auto;
  }
  .logo-beta {
    position: absolute;
    top: 65px;
    left: calc(18% + 128px);
  }
  .logo-title {
    font-family: MontserratMedium;
    position: absolute;
    top: 42px;
    left: 18%;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    letter-spacing: 0.4px;
  }

  .left-wrapper {
    position: absolute;
    left: 18%;
    top: calc(25% + 15px);

    .name {
      font-weight: 700;
      font-size: 50px;
      line-height: 63px;
      color: #ffffff;
    }

    .title {
      margin: 6px 0 0 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.4);
    }

    .subtitle {
      margin: 56px 0 0 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: #ffffff;

      .subtitle-item {
        margin: 27px 0 0 0;
      }
    }
  }
}

@primary-color: #2f54eb;
.register-container {
  width: 100%;
  min-width: 1024px;
  height: 100vh;
  background-color: rgba(36, 66, 191, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: auto;

  .register-wrapper {
    flex: 10;
    height: 100vh;
    background-color: #f9f9f9;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .register-header--manual {
      margin-right: 10px;
    }
    .register-header-language-switch {
      width: 502px;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      top: 57px;
    }
    .register-form {
      background: #ffffff;
      border-radius: 8px;
      padding: 52px 38px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .register-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #000824;
      }

      .custom-register-form {
        .custom-register-item {
          margin: 32px 0 0 0;
        }

        .custom-register-input-wrapper {
          display: flex;
          align-items: flex-start;
          .custom-register-input {
            flex: 1;
            margin-right: 8px;
          }
          .custom-register-code {
            height: 37px;
          }
        }

        .register-button {
          margin: 32px 0 0 0;
          width: 426px;
          height: 46px;
          font-weight: 600;
          font-size: 17px;
          line-height: 21px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .other-option {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .login-button {
      font-weight: 600;
      font-size: 17px;
      margin: 0 0 0 -10px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.tooltip-wrapper {
  padding: 14px;

  .tooltip-title {
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 1px;
    color: #a6a6a6;
  }

  .tooltip-item {
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.2px;
    color: #000000;
    margin: 12px 0 0 0;
    display: flex;
    align-items: center;
    .img-wrapper {
      width: 20px;
    }

    .validate-pass {
      color: #52c41a;
    }
  }
}

@primary-color: #2f54eb;
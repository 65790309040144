.language-switch-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #646464;
  font-size: 14px;
}

@primary-color: #2f54eb;
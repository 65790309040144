.get-code {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #2f54eb;
  margin-right: 8px;

  &:hover {
    cursor: pointer;
  }
}

.get-code-disabled {
  color: #9b9b9b;

  &:hover {
    cursor: default;
  }
}

@primary-color: #2f54eb;
.thin-spinner-container {
  width: 100%;
  height: 100%;
  position: relative;
  .thin-spinner-class {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap: 8px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, 0);
    color: #646464;
  }
}

@primary-color: #2f54eb;
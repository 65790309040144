.m-confirm-modal {
  .title-container {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    .title-label {
      font-size: 20px;
      font-family: Outfit, 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 500;
      line-height: 29px;
      color: rgba(0, 0, 0, 0.85);
    }
    .title-close-btn {
      margin-left: auto;
    }
  }
  .MuiDialogContent-root {
    padding-bottom: 20px;
  }
  .MuiDialogActions-root {
    padding: 8px 24px 20px 8px;
  }
}

@primary-color: #2f54eb;
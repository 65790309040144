@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 1em;
  --scrollbar-bg: transparent;
  --scrollbar-color: rgba(0, 0, 0, 0.25);
}
/* Scrollbar customizations */
::-webkit-scrollbar-track {
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--scrollbar-bg, auto);
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  border: 2px solid var(--scrollbar-bg, auto);
  background: var(--scrollbar-color);
}
.full-height {
  height: 100%;
}
.table-height {
  height: calc(100% - 50px);
}
.no-left-padding {
  padding-left: 0px;
  fieldset {
    padding-left: 0px;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  .tab-content {
    flex: 1;
    overflow: auto;
  }
}
.page-content-wrapper {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.mg-card-wrapper {
  padding: 16px;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.center-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.btn {
  // border-radius: 2px;
  border: 1px solid;
}
.btn-primary {
  background-color: #2f54eb;
  border-color: #1d39c4;
  &:hover {
    background-color: #1d39c4;
    border-color: #1d39c4;
    outline: none;
  }
  &:active {
    background-color: #1d39c4;
    border-color: #1d39c4;
    outline: none;
  }
  &:active:hover {
    background-color: #1d39c4;
    border-color: #1d39c4;
    outline: none;
  }
  &:active:focus {
    background-color: #1d39c4;
    border-color: #1d39c4;
    outline: none;
  }
  &:focus {
    background-color: #1d39c4;
    border-color: #1d39c4;
    outline: none;
  }
  &[disabled]:hover {
    background-color: #2f54eb;
    border-color: #1d39c4;
    outline: none;
  }
}

.MuiButton-containedPrimary {
  background-color: #2442bf;
  color: #fff;
  border-color: #1d39c4;
  &:hover {
    background-color: #1d39c4 !important;
    color: #fff !important;
    border-color: #1d39c4 !important;
    outline: none !important;
  }
  &:active {
    background-color: #1d39c4 !important;
    color: #fff !important;
    border-color: #1d39c4 !important;
    outline: none !important;
  }
  &:active:hover {
    background-color: #1d39c4 !important;
    color: #fff !important;
    border-color: #1d39c4 !important;
    outline: none !important;
  }
  &:active:focus {
    background-color: #1d39c4 !important;
    color: #fff !important;
    border-color: #1d39c4 !important;
    outline: none !important;
  }
  &:focus {
    background-color: #1d39c4 !important;
    color: #fff !important;
    border-color: #1d39c4 !important;
    outline: none !important;
  }
}

.json-schema-wrapper {
  .MuiFormControl-fullWidth {
    margin: 0 0 10px 0;
  }

  .MuiPaper-elevation2 {
    box-shadow: none;
    border: 1px solid #ccc;
  }
}

.btn-default {
  background-color: #fff;
  border-color: #2442bf;
  color: rgba(47, 84, 235, 1);
  &:hover {
    color: rgba(47, 84, 235, 1);
    background-color: #d6e4ff;
    border-color: #2442bf;
    outline: none;
  }
  &:active {
    color: rgba(47, 84, 235, 1);
    background-color: #d6e4ff;
    border-color: #2442bf;
    outline: none;
  }
  &:active:hover {
    color: rgba(47, 84, 235, 1);
    background-color: #d6e4ff;
    border-color: #2442bf;
    outline: none;
  }
  &:active:focus {
    color: rgba(47, 84, 235, 1);
    background-color: #d6e4ff;
    border-color: #2442bf;
    outline: none;
  }
  &:focus {
    color: rgba(47, 84, 235, 1);
    background-color: #d6e4ff;
    border-color: #2442bf;
    outline: none;
  }
}

.btn-success {
  background-color: #38a78a;
  border-color: #38a78a;
  &:hover {
    background-color: #118668;
    border-color: #118668;
    outline: none;
  }
  &:active {
    background-color: #118668;
    border-color: #118668;
    outline: none;
  }
  &:active:hover {
    background-color: #118668;
    border-color: #118668;
    outline: none;
  }
  &:active:focus {
    background-color: #118668;
    border-color: #118668;
    outline: none;
  }
  &:focus {
    background-color: #118668;
    border-color: #118668;
    outline: none;
  }
}

.btn-info {
  background-color: #55bef0;
  border-color: #55bef0;
  &:hover {
    background-color: #2998cc;
    border-color: #2998cc;
    outline: none;
  }
  &:active {
    background-color: #2998cc;
    border-color: #2998cc;
    outline: none;
  }
  &:active:hover {
    background-color: #2998cc;
    border-color: #2998cc;
    outline: none;
  }
  &:active:focus {
    background-color: #2998cc;
    border-color: #2998cc;
    outline: none;
  }
  &:focus {
    background-color: #2998cc;
    border-color: #2998cc;
    outline: none;
  }
}

.btn-warning {
  background-color: #f5bc4a;
  border-color: #f5bc4a;
  &:hover {
    background-color: #dea125;
    border-color: #dea125;
    outline: none;
  }
  &:active {
    background-color: #dea125;
    border-color: #dea125;
    outline: none;
  }
  &:active:hover {
    background-color: #dea125;
    border-color: #dea125;
    outline: none;
  }
  &:active:focus {
    background-color: #dea125;
    border-color: #dea125;
    outline: none;
  }
  &:focus {
    background-color: #dea125;
    border-color: #dea125;
    outline: none;
  }
}

// .btn-danger {
//   background-color: #FF0000;
//   border-color: #FF0000;
//   &:hover {
//     background-color: #8e2600;
//     border-color: #8e2600;
//     outline: none;
//   }
//   &:active {
//     background-color: #8e2600;
//     border-color: #8e2600;
//     outline: none;
//   }
//   &:active:hover {
//     background-color: #8e2600;
//     border-color: #8e2600;
//     outline: none;
//   }
//   &:active:focus {
//     background-color: #8e2600;
//     border-color: #8e2600;
//     outline: none;
//   }
//   &:focus {
//     background-color: #8e2600;
//     border-color: #8e2600;
//     outline: none;
//   }
// }

.btn-link {
  background-color: transparent;
  border-color: #2442bf;
  color: rgba(47, 84, 235, 1);
  border: 1px solid transparent;
  &:hover {
    background-color: transparent;
    border-color: #2442bf;
    color: #2442bf;
    outline: none;
    border: 1px solid transparent;
  }
  &:active {
    background-color: transparent;
    border-color: #2442bf;
    color: #2442bf;
    outline: none;
    border: 1px solid transparent;
  }
  &:active:hover {
    background-color: transparent;
    border-color: #2442bf;
    color: #2442bf;
    outline: none;
    border: 1px solid transparent;
  }
  &:active:focus {
    background-color: transparent;
    border-color: #2442bf;
    color: #2442bf;
    outline: none;
    border: 1px solid transparent;
  }
  &:focus {
    background-color: transparent;
    border-color: #2442bf;
    color: #2442bf;
    outline: none;
    border: 1px solid transparent;
  }
}
.label-color {
  color: #888;
}
.padding-spacing-1 {
  padding: 4px;
}
.padding-spacing-2 {
  padding: 8px;
}
.padding-spacing-3 {
  padding: 12px;
}

.MuiIconButton-sizeSmall {
  width: 37.28px;
  height: 37.28px;
}

input:-internal-autofill-selected {
  -webkit-text-fill-color: #3b3b3b !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: #fff !important;
  background-image: none !important;
}

.ant-select-dropdown {
  z-index: 9999 !important;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: #d5d6dc;
}
label.megacloud-radio-button-wrapper {
  font-weight: normal;
}

@primary-color: #2f54eb;